import constants from '../constants';
import { addMessage } from 'store/actions/messages';
import { SubmissionError } from 'redux-form';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import beep from 'assets/audio/beep';
import * as moment from 'moment-timezone';

export const authSetup = () => ({
  type: constants.AUTH_SETUP,
})

export const authorize = (username, password) => {

  const formData = new FormData();
  formData.append('json', JSON.stringify({username, password}));

  const url = handleCiviURLBuild('Authorization','ufauthorize');

  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: 'Bearer ' + process.env.REACT_APP_DEFAULT_API_KEY,
      'X-Requested-With': 'XMLHttpRequest',
    }, 
    body: formData,
  })
  .then(response => response.json())
  .then(json => {
    // console.log(json);
    if (json.is_error)
      throw new Error(json.error_message);
    return json;
  });
};

export const login = (username, password) => dispatch => {

  dispatch({
    type: constants.TRY_LOGIN,
  });

  const formData = new FormData();
  formData.append('json', JSON.stringify({username, password}));

  return authorize(username, password)
  .then(json => {
    dispatch({
      type: constants.LOGIN,
      value: {
        loggedInApiKey: json.values.api_key,
        contactId: json.values.contact_id,
        contact: json.values.contact,
      }
    });
    // dispatch(addMessage('Logged in', 'success'));
    beep.play();
    dispatch(checkinScan(json.values.contact_id, json.values.api_key));
    return json;
  })
  .catch(error => {
    dispatch({
      type: constants.FAIL_LOGIN,
    });
    dispatch(addMessage(error.message, 'error'));
    return false;
  });
};

export const barcodeAuthorize = barcode => {


  const url = handleCiviURLBuild('Authorization','barcodeauthorize');

  const formData = new FormData();
  formData.append('json', JSON.stringify({ barcode }));

  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: 'Bearer ' + process.env.REACT_APP_DEFAULT_API_KEY,
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: formData,
  })
  .then(response => response.json())
  .then(json => {
    if (json.is_error)
      throw new Error(json.error_message);
    return json;
  })
};

export const barcodeLogin = barcode => dispatch => {

  dispatch({
    type: constants.TRY_LOGIN,
  });

  return barcodeAuthorize(barcode)
  .then(json => {
    dispatch({
      type: constants.LOGIN,
      value: {
        loggedInApiKey: json.values.api_key,
        contactId: json.values.contact_id,
        contact: json.values.contact,
      }
    });
    // dispatch(addMessage('Logged in', 'success'));
    beep.play();
    dispatch(checkinScan(json.values.contact_id, json.values.api_key));
    return json;
  })
  .catch(error => {
    dispatch({
      type: constants.FAIL_LOGIN,
    });
    dispatch(addMessage(error.message, 'error'));
    return false;
  });
};

export const logout = () => dispatch => {
  dispatch({
    type: constants.LOGOUT,
  });
}

const checkinScan = (contact_id, contact_api_key) => dispatch => {

  const now = moment().tz("America/Los_Angeles");

  const json = {
    sequential: 1,
    activity_type_id: '182', // Kiosk qr scan
    target_contact_id: contact_id,
    status_id: 'Completed',
    activity_date_time: now.format('YYYY-MM-DD HH:mm:ss'),
    subject: "Kiosk - Card Scan / Login",
  };

  const formData = new FormData();
  formData.append('json', JSON.stringify(json));

  const url = handleCiviURLBuild('Request','create');

  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: 'Bearer ' + contact_api_key,
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: formData,
  })
  .then(response => response.json())
  .then(json => {
    if (json.is_error) {
     throw new Error(json.error_message);
    }
    dispatch({
      type: constants.CHECK_IN,
      value: Object.values(json.values)[0],
    })
    return json.values[0];
  })
  .catch(error => {
    dispatch(addMessage(error.message, 'error'));
    return false;
  });
}

export const checkin = (contact_id, contact_api_key, approved_contact_id=null) => dispatch => {

  /**
   * Validate
   */

  const errors = {};

  const errorKeys = Object.keys(errors);
  if (errorKeys.length>0) {
    dispatch(addMessage('Please fill out all required information.', 'error'));
    // throw new SubmissionError({
    //   auth: authErrors,
    //   contact: contactErrors,
    // });
    throw new SubmissionError({
      _error: errorKeys.map(key => errors[key]).join(', '),
    });
  }

  /**
   * Save
   */
  
  const now = moment().tz("America/Los_Angeles");

  const json = {
    sequential: 1,
    activity_type_id: '180', // Kiosk qr scan / login
    target_contact_id: contact_id,
    status_id: 'Completed',
    activity_date_time: now.format('YYYY-MM-DD HH:mm:ss'),
    subject: "Kiosk - Check-in",
  };
  if (approved_contact_id)
    json.custom_1324 = approved_contact_id;
  // console.log(json);

  const formData = new FormData();
  formData.append('json', JSON.stringify(json));

  const url = handleCiviURLBuild('Request','create');

  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: 'Bearer ' + contact_api_key,
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: formData,
  })
  .then(response => response.json())
  .then(json => {
    // console.log(json);
    if (json.is_error)
      throw new Error(json.error_message);
    dispatch(addMessage('You are checked in', 'success'));
    // handleUpdateCurrentState(''); // refresh this page
    dispatch(logout());
    return json;
  })
  .catch(error => {
    dispatch(addMessage(`There was an issue with your check in (${error.message})`, 'error'));
    return false;
  });

}