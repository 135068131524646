import React, { Component } from 'react';
import { connect } from 'react-redux';
import { checkin, logout } from 'store/actions/auth';
import {
  updateCurrentState,
  updateLoadingState,
  setSingleData,
} from 'store/actions/single';
import { addMessage } from 'store/actions/messages';
import { Form, reduxForm } from 'redux-form';
import { Box, Button, Heading } from 'grommet';
import { Checkmark, Close, Validate } from 'grommet-icons';
import './Checkin/styles.css';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import Map from './Checkin/Map';
import StillHere from './Checkin/StillHere';
import WelcomeHeading from './Checkin/WelcomeHeading';
import restrictionLengthList from 'helpers/restrictionLengths';
import restrictionReasonsList from 'helpers/restrictionReasons';
import * as moment from 'moment-timezone';
import ApprovedByDialog from './Checkin/ApprovedByDialog';

const handlers = {
  'click': null,
  'scroll': null,
  'touchstart': null,
  'touchend': null,
  'touchmove': null,
  'touchcancel': null,
  'keydown': null,
  'keyup': null,
};

class Checkin extends Component {

  constructor(props){
    super(props);
    this.state = {
      stillHereTimeout: null,
      stillHereOpen: false,
      approvedByOpen: false,
    };
  }

  componentDidMount() {
    
    this.loadData();

    // setup events to trigger still here
    this.setupStillHereTimeout();
    const handler = e => this.setupStillHereTimeout();
    Object.keys(handlers).forEach(eventName => {
      handlers[eventName] = handler;
      window.addEventListener(eventName, handler, true);
    });
  }

  componentDidUpdate() {
    this.loadData();
  }

  componentWillUnmount() {

    if (this.state.stillHereTimeout!==null) {
      clearTimeout(this.state.stillHereTimeout);
    }

    // remove events that trigger still here
    Object.keys(handlers).forEach(eventName => {
      handlers[eventName] && window.removeEventListener(eventName, handlers[eventName], true);
    });
  }

  calculateCurrentState = () => {
    const tableState = [
      this.props.contactId,
    ].join('|');
    return tableState;
  }

  loadData = () => {
    
    const currentState = this.calculateCurrentState();
    const { state, loadingState } = this.props;
    // console.log({ currentState, state, loadingState})
    if (
      currentState === state ||
      currentState === loadingState
    ) return;

    this.props.handleUpdateLoadingState(currentState);

    const returnFields = [
      // intake (on contact record FYs 19-24)
      'custom_1256', // 2018-2019 intake date/time
      'custom_1325', // 2019-2020 intake date/time
      'custom_1409', // 2020-2021 intake date/time
      'custom_1523', // 2021-2022 intake date/time
      'custom_1869', // 2022-2023 intake date/time
      'custom_2203', // 2023-2024 intake date/time      
      // restricted
      'custom_367', // Date of Restriction
      'custom_934', // Reason for Restriction
      'custom_935', // Length of Restriction
      'custom_370', // Who they need to speek to
      'custom_371', // Description
    ];

    const activityUrl = handleCiviURLBuild('Request', 'get', {
      "activity_type_id": "266",
      "status_id": "Completed",
      "options":{
        "sort":"activity_date_time DESC",
        "limit": 1,
      },
      "sequential": 1,
    });
    const activityFetch = fetch(activityUrl, {
        method: "GET",
        headers: {
          Authorization: 'Bearer ' + this.props.apiKey,
          'X-Requested-With': 'XMLHttpRequest',
        },
      },
    )
    .then(response => response.json())
    .then(json => {
      if (json.is_error)
        throw new Error(json.error_message);
      return json;
    })
    .then(results => {
      const data = {
        intakeDate: null,
      };
      if (results.values.length === 0) {
        return data;
      }
      const { activity_date_time } = results.values[0];
      data.intakeDate = moment.tz(activity_date_time, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles").format('MMMM Do, YYYY');
      return data;
    });

    const contactUrl = handleCiviURLBuild('Contact','get',{
      'sequential': 1,
      'id': this.props.contactId,
      'return': returnFields,
    });
    
    const contactFetch = fetch(contactUrl, {
      method: "GET",
      headers: {
        Authorization: 'Bearer ' + this.props.apiKey,
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
    .then(response => response.json())
    .then(json => {
      if (json.is_error)
        throw new Error(json.error_message);
      return json;
    })
    .then(data => data.values[0])
    .then(data => {
      // civicrm is inconsistant in returning variables
      returnFields.forEach(field => {
        if (!(field in data))
          data[field] = '';
      });

      const {
        // custom_1256, // 2018-2019 Intake Date
        // custom_1325, // 2019-2020 Intake Date
        // custom_1409, // 2020-2021 Intake Date
        // custom_1523, // 2021-2022 Intake Date
        // custom_1869, // 2022-2023 Intake Date
        // custom_2203, // 2023-2024 Intake Date
        custom_367,
        custom_934,
        custom_935
      } = data;

      // const now = moment().tz("America/Los_Angeles");
      // const year  = parseInt(now.format('Y'));
      // const month  = parseInt(now.format('M'));
      // data.intakeDate = null;
      // if ((year===2018 && month>=7) || (year===2019 && month<7)) {
      //   // 2018-2019
      //   data.intakeDate = custom_1256 ? moment.tz(custom_1256, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles").format('MMMM Do, YYYY') : null;
      // } else if ((year===2019 && month>=7) || (year===2020 && month<7)) {
      //   // 2019-2020
      //   data.intakeDate = custom_1325 ? moment.tz(custom_1325, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles").format('MMMM Do, YYYY') : null;
      // } else if ((year===2020 && month>=7) || (year===2021 && month<7)) {
      //   // 2020-2021
      //   data.intakeDate = custom_1409 ? moment.tz(custom_1409, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles").format('MMMM Do, YYYY') : null;
      // } else if ((year===2021 && month>=7) || (year===2022 && month<7)) {
      //   // 2021-2022
      //   data.intakeDate = custom_1523 ? moment.tz(custom_1523, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles").format('MMMM Do, YYYY') : null;
      // } else if ((year===2022 && month>=7) || (year===2023 && month<7)) {
      //   // 2022-2023
      //   data.intakeDate = custom_1869 ? moment.tz(custom_1869, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles").format('MMMM Do, YYYY') : null;
      // } else if ((year===2023 && month>=7) || (year===2024 && month<7)) {
      //   // 2023-2024
      //   data.intakeDate = custom_2203 ? moment.tz(custom_2203, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles").format('MMMM Do, YYYY') : null;
      // } else if ((year===2024 && month>=7) || (year===2025 && month<7) || true) {
      //   // 2024-2025
      //   // Beyond here, we use activities
      // }

      data.restrictionDate = custom_367 ? moment.tz(custom_367, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles").format('MMMM Do, YYYY') : null;

      const restrictionLength = restrictionLengthList.find(length => length.value===custom_935);
      data.restrictionLength = restrictionLength ? restrictionLength.label : null;

      const restrictionReasons = restrictionReasonsList.filter(reason => custom_934 && custom_934.includes(reason.value));
      data.restrictionReasons = restrictionReasons ? restrictionReasons.map(reason => reason.label).join(', ') : null;    

      return data;
    })

    Promise.all([contactFetch, activityFetch])
    .then(dataList => {
      const data = dataList.reduce(function(prev, curr){
        return Object.assign({}, prev, curr);
      }, {});
      // console.log(data);
      // update list data
      this.props.handleSetSingleData(data);
      this.props.handleUpdateCurrentState(currentState);
      this.props.handleUpdateLoadingState('');
    })
    .catch(e => {
      this.props.handleMessage(e.message, 'error');
    });
    
  }

  submitForm = (values) => {
    const { contactId, apiKey, handleCheckin } = this.props;
    handleCheckin(contactId, apiKey);
  }

  setupStillHereTimeout = () => {

    if (this.state.stillHereTimeout!==null) {
      clearTimeout(this.state.stillHereTimeout);
    }

    const stillHereTimeout = setTimeout(() => this.toggleStillHere(), 60000); // 60 seconds
    this.setState({ stillHereTimeout });
  }

  toggleStillHere = (state=null) => {

    const stillHereOpen = state===null ? !this.state.stillHereOpen : state;
    if (!stillHereOpen) {
      this.setupStillHereTimeout();
    }
    this.setState({ stillHereOpen });
  }

  toggleApprovedByOpen = (state=null) => this.setState({approvedByOpen: state===null ? !this.state.approvedByOpen : state})

  render() {

    const { contact, extraContactData, handleLogout, handleSubmit, isFetching } = this.props;
    const { stillHereOpen, approvedByOpen } = this.state;

    const { image_URL } = contact;
    let contactImage = null;
    const imageContainerStyles = {};
    if (image_URL) {
      const elem = document.createElement('textarea');
      elem.innerHTML = image_URL;
      contactImage = elem.value;
      imageContainerStyles.backgroundImage = `url('${contactImage}')`;
    }

    return (
      <Box className="Checkin" direction='column' flex align="center" justify="center">
        <Box className="headingbox" basis="15%">
          <WelcomeHeading />
        </Box>
        <Box className="clientbox" basis="80%" border={{ size: 'medium' }} pad="medium">
          {extraContactData==null ? 
            <Box>Loading...</Box>
          : (
            <Box direction="row" fill>
              <Box basis="40%" direction='column' fill margin={{ right: 'small' }} justify="center" align="center">
                {contactImage ? <Box className="clientimagebox" style={imageContainerStyles} fill></Box> : <Box>-- No Image --</Box> }
              </Box>
              <Box basis="60%" margin={{ left: 'small' }} pad={{ left: 'medium', top: 'medium' }}>
                <Form onSubmit={handleSubmit(this.submitForm)}>
                  {!isFetching && contact.contact_sub_type.includes("YCH_Restricted") &&
                    <Box border="bottom" background="status-critical" style={{ textAlign: 'center' }}>
                      <Heading level="3" margin="small">RESTRICTED</Heading>
                    </Box>
                  }
                  <Box direction="row" border="bottom">
                    <Box basis="1/2" pad="xsmall"><strong>Client ID #</strong></Box>
                    <Box basis="1/2" pad="xsmall">{contact.id}</Box>
                  </Box>
                  <Box direction="row" border="bottom">
                    <Box basis="1/2" pad="xsmall"><strong>Client Name</strong></Box>
                    <Box basis="1/2" pad="xsmall">{contact.nick_name || contact.display_name}</Box>
                  </Box>
                  {/*
                    custom_1256, // 2018-2019 Intake Date
                    custom_1325, // 2019-2020 Intake Date
                    custom_1409, // 2020-2021 Intake Date
                    custom_1523, // 2021-2022 Intake Date
                    custom_1869, // 2022-2023 Intake Date
                    custom_2203, // 2023-2024 Intake Date
                    custom_367, // Date of Restriction
                    custom_934, // Reason for Restriction
                    custom_935, // Length of Restriction
                    custom_370, // Who they need to speek to
                    custom_371, // Description
                  */}
                  {!isFetching &&
                    <Box direction="row" border="bottom" background={extraContactData.intakeDate ? "transparent" : "status-critical"}>
                      <Box basis="1/2" pad="xsmall"><strong>Last Intake Date</strong></Box>
                      <Box basis="1/2" pad="xsmall">{extraContactData.intakeDate}</Box>
                    </Box>
                  }
                  {!isFetching && contact.contact_sub_type.includes("YCH_Restricted") ?
                    <>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>Date of Restriction</strong></Box>
                        <Box basis="1/2" pad="xsmall">{extraContactData.restrictionDate}</Box>
                      </Box>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>Length of Restriction</strong></Box>
                        <Box basis="1/2" pad="xsmall">{extraContactData.restrictionLength}</Box>
                      </Box>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>Reason for Restriction</strong></Box>
                        <Box basis="1/2" pad="xsmall">{extraContactData.restrictionReasons}</Box>
                      </Box>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>Description of Restriction</strong></Box>
                        <Box basis="1/2" pad="xsmall">{extraContactData.custom_371}</Box>
                      </Box>
                      <Box direction="row" border="bottom" background="accent-1">
                        <Box basis="1/2" pad="xsmall"><strong>Verified Approvers</strong></Box>
                        <Box basis="1/2" pad="xsmall">{extraContactData.custom_370}</Box>
                      </Box>
                      <Box direction="row" justify="center" align="center">
                        <Box pad={{ top: "small", bottom: "xsmall", left: "xsmall", right: "xsmall" }}>
                          <Button margin="none" label="Cancel" icon={<Close />} onClick={() => handleLogout()} />
                        </Box>
                        <Box pad={{ top: "small", bottom: "xsmall", left: "xsmall", right: "xsmall" }}>
                          {extraContactData.intakeDate ?
                            <Button margin="none" label="Approved By" color="neutral-1" icon={<Validate />} onClick={() => this.toggleApprovedByOpen(true)} primary={true} />
                          :
                            "Please see desk to complete an intake"
                          }
                        </Box>
                      </Box>
                    </>
                  :
                    <Box direction="row" justify="center" align="center">
                      <Box pad={{ top: "small", bottom: "xsmall", left: "xsmall", right: "xsmall" }}>
                        <Button margin="none" label="Cancel" icon={<Close />} onClick={() => handleLogout()} />
                      </Box>
                      <Box pad={{ top: "small", bottom: "xsmall", left: "xsmall", right: "xsmall" }}>
                        {extraContactData.intakeDate ?
                          <Button margin="none" label="Confirm" color="neutral-1" icon={<Checkmark />} type="submit" primary={true} />
                        :
                          "Please see desk to complete an intake"
                        }
                      </Box>
                    </Box>
                  }
                </Form>
                <ApprovedByDialog open={approvedByOpen} toggle={this.toggleApprovedByOpen} />
              </Box>
            </Box>
          )}
        </Box>
        <Map />
        <StillHere open={stillHereOpen} toggle={this.toggleStillHere} />
      </Box>
    );
  }
}

const mapStateToProps = (state) => {

  const { 
    auth: { contact, contactId, apiKey },
    display: { mobileMode },
    single: { extraContact },
  } = state;

  const {
    isFetching,
    data,
    loadingState,
  } = extraContact;
  
  return {
    contact,
    contactId,
    apiKey,
    mobileMode,

    isFetching,
    extraContactData: data,
    state: extraContact.state,
    loadingState,
  };
}

const mapDispatchToProps = dispatch => {

  return {
    handleLogout: () => dispatch(logout()),
    handleMessage: (message, variant=null, logout=false) => dispatch(addMessage(message, variant, logout)),
    handleUpdateCurrentState: state => dispatch(updateCurrentState('extraContact', state)),
    handleUpdateLoadingState: loadingState => dispatch(updateLoadingState('extraContact', loadingState)),
    handleSetSingleData: data => dispatch(setSingleData('extraContact', data)),
    handleCheckin: (contact_id, apiKey) => dispatch(checkin(contact_id, apiKey)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'checkin',
  })(Checkin)
);
