import React from 'react';
import preval from 'preval.macro';

const About = props => (
  <div className="About">
    <dl>
      <dt>Captain's Log</dt>
      <dd>Jean-Luc Picard</dd>
      <dt>Star Date</dt>
      <dd>
        {preval`
          const moment = require('moment-timezone');
          const now = moment().tz("America/Los_Angeles");
          const format = 'YYYY-MM-DD HH:mm:ss';
          module.exports = now.format(format);
        `}
      </dd>
      <dt>Life Support</dt>
      <dd>Operable</dd>
      <dt>Current Speed</dt>
      <dd>Defying the laws of physics</dd>
    </dl>
  </div>
);

export default About;