import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from 'store/actions/auth';
import { addMessage } from 'store/actions/messages';
import { Box, Button, Heading } from 'grommet';
import { Close } from 'grommet-icons';
import * as moment from 'moment-timezone';

class WelcomeHeading extends Component {

  constructor(props) {
    super(props);
    this.state ={
      timeInterval: null,
      currentTime: null,
    };
  }

  componentDidMount() {
    const timeInterval = setInterval( () => {
      const now = moment().tz("America/Los_Angeles");
      this.setState({
        currentTime : <span>{now.format('MMMM Do')}<br />{now.format('h:mm a').replace('am', 'a.m.').replace('pm', 'p.m.')}</span>,
      })
    },1000);
    this.setState({ timeInterval });
  }

  componentWillUnmount() {
    if (this.state.timeInterval!==null) {
      clearInterval(this.state.timeInterval);
    }
  }

  render() {

    const { contact, /* disabled, */ mobileMode, handleLogout } = this.props;
    const { currentTime } = this.state;

    return (
      <Box className="WelcomeHeading" flex justify="between" align="center" direction="row-responsive" wrap>
        <Box flex basis="35%" style={{ textAlign: mobileMode ? 'center' : 'left' }}>
          <Heading level="1" margin="none">CYFS Check In</Heading>
        </Box>
        <Box flex basis="15%" style={{ textAlign: mobileMode ? 'center' : 'left' }}>
          <Box direction="row">
            <Heading level="2" margin="none">
              Welcome<br />{contact && (contact.nick_name || contact.display_name)}
            </Heading>
          </Box>
        </Box>
        <Box flex basis="15%" style={{ textAlign: 'center' }}>
          <Heading level="2" margin="none">
            {currentTime}
          </Heading>
        </Box>
        <Box flex basis="25%" style={{ textAlign: mobileMode ? 'center' : 'right' }}>
          <div>
            <Button margin="none" label="Cancel" icon={<Close />} onClick={() => handleLogout()} />
          </div>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    auth: { contact },
    display: { mobileMode },
   } = state;
  return {
    contact,
    mobileMode,
  };
}

const mapDispatchToProps = dispatch => {

  return {
    handleMessage: (message, variant=null, logout=false) => dispatch(addMessage(message, variant, logout)),
    handleLogout: () => dispatch(logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeHeading);